import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-all-modules',
  templateUrl: './all-modules.component.html',
  styleUrls: ['./all-modules.component.css']
})
export class AllModulesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
