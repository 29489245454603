import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(private httpClient: HttpClient) {
  }

  // TODO: Cache me
  public getModules() {
    return this.httpClient.get(`${environment.apiUrl}/modules`);
  }

  public getModule(name: string) {
    return this.httpClient.get(`${environment.apiUrl}/module/${name}`);
  }

  public getPastPapers(module: string) {
    return this.httpClient.get(`${environment.apiUrl}/module/${module}/past-papers`, {withCredentials: true});
  }

  public getCategories() {
    return this.httpClient.get(`${environment.apiUrl}/categories`);
  }
}
