import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ApiService} from '../api.service';

@Component({
  selector: 'app-module-detail',
  templateUrl: './module-detail.component.html',
  styleUrls: ['./module-detail.component.css']
})
export class ModuleDetailComponent implements OnInit {
  hasLoaded = false;
  module: Module;

  constructor(private route: ActivatedRoute, private service: ApiService) {
  }

  ngOnInit() {
    this.module = undefined;
    this.route.paramMap.subscribe(params => {
      this.service.getModule(params.get('id')).subscribe((module: Module) => {
        this.module = module;
      });
    });
  }

}

interface Module {
  prefix: string;
  code: string;
  name: string;
  credits: string;
  period: string;
  prerequisites: Array<string>;
}
