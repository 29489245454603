import {NgModule} from '@angular/core';
import {Routes, RouterModule, UrlSegment} from '@angular/router';
import {ModuleTilesComponent} from './module-tiles/module-tiles.component';
import {ModuleDetailComponent} from './module-detail/module-detail.component';
import {FullPageComponent} from './full-page/full-page.component';
import {LeftSidebarPageComponent} from './left-sidebar-page/left-sidebar-page.component';
import {HomeComponent} from './home/home.component';
import {AllModulesComponent} from './all-modules/all-modules.component';
import {ErrorComponent} from './error/error.component';


const routes: Routes = [
  {
    path: '',
    component: FullPageComponent,
    children: [
      {path: '', component: HomeComponent, pathMatch: 'full'},
      {path: 'modules', component: AllModulesComponent, pathMatch: 'full'}
      // {
      //   matcher: (url) => {
      //     if (url.length === 3 && url[0].path.match(/^[\w]{3}$/gm)) {
      //       return {
      //         consumed: url,
      //         posParams: {
      //           category: new UrlSegment(url[0].path.substr(0, 3), {})
      //         }
      //       };
      //     }
      //     return null;
      //   },
      //   component: ModuleTilesComponent
      // }
    ]
  },
  {
    path: 'module/:id',
    component: LeftSidebarPageComponent,
    children: [
      {path: '', component: ModuleDetailComponent, pathMatch: 'full'}
    ]
  },
  {
    path: 'forbidden',
    component: FullPageComponent,
    children: [
      {path: '', component: ErrorComponent, pathMatch: 'full'}
    ]
  }
  // {path: '/modules/:id', redirectTo: '/module/:id', pathMatch: 'full'}, FIXME
  // {path: '**', redirectTo: '/module/COS404', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {enableTracing: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
