import { Component, OnInit } from '@angular/core';
import {faCubes} from '@fortawesome/free-solid-svg-icons';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-left-sidebar-page',
  templateUrl: './left-sidebar-page.component.html',
  styleUrls: ['./left-sidebar-page.component.css']
})
export class LeftSidebarPageComponent implements OnInit {
  faCubes = faCubes;
  module: string;

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.module = params.get('id');
    });
  }

}
