import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {faFilePdf} from '@fortawesome/free-regular-svg-icons';
import {ApiService} from '../api.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-past-paper-list',
  templateUrl: './past-paper-list.component.html',
  styleUrls: ['./past-paper-list.component.css']
})
export class PastPaperListComponent implements OnInit {
  faFilePdf = faFilePdf;
  files;

  @Output() finishedLoading: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private route: ActivatedRoute, private apiService: ApiService) {
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      const module = params.get('id');
      this.apiService.getPastPapers(module).subscribe((data: Array<any>) => {
        this.files = data.reduce((r, a) => {
          r[a.category] = r[a.category] || [];
          r[a.category].push(a);
          return r;
        }, Object.create(null));
        this.finishedLoading.emit(true);
      });
    });
  }

}
