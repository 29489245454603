import {Component, OnInit} from '@angular/core';
import {ApiService} from '../api.service';
import {faCubes} from '@fortawesome/free-solid-svg-icons';
import {Router} from '@angular/router';

@Component({
  selector: 'app-module-tiles',
  templateUrl: './module-tiles.component.html',
  styleUrls: ['./module-tiles.component.css']
})
export class ModuleTilesComponent implements OnInit {
  modules;
  filterText;
  faCoffee = faCubes;

  constructor(private apiService: ApiService, private router: Router) {
  }

  ngOnInit() {
    this.apiService.getCategories().subscribe((data: Array<TileData>) => {
      this.modules = data.sort((a, b) => (a.name > b.name) ? 1 : -1);
      console.log(this.modules);
    });
  }

  click(category) {
    this.modules = null;
    if (this.filterText === undefined) {
      this.filterText = category;
      this.apiService.getModules().subscribe((modules: Array<any>) => {
        this.modules = modules.filter(module => module.prefix === category).sort((a, b) => a.code - b.code).map(module => {
          return {
            name: module.prefix + module.code,
            description: module.name
          };
        });
      });
    } else {
      this.router.navigate(['module', category]);
    }
  }
}

interface TileData {
  name: string;
  description: string;
}
