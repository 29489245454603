import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {HttpClientModule} from '@angular/common/http';
import { ModuleTilesComponent } from './module-tiles/module-tiles.component';
import { FooterComponent } from './footer/footer.component';
import { ModuleDetailComponent } from './module-detail/module-detail.component';
import { LeftSidebarPageComponent } from './left-sidebar-page/left-sidebar-page.component';
import { FullPageComponent } from './full-page/full-page.component';
import { PastPaperListComponent } from './past-paper-list/past-paper-list.component';
import { SearchComponent } from './search/search.component';
import { HomeComponent } from './home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatFormFieldModule, MatInputModule, MatProgressSpinnerModule} from '@angular/material';
import { AllModulesComponent } from './all-modules/all-modules.component';
import { ErrorComponent } from './error/error.component';

@NgModule({
  declarations: [
    AppComponent,
    ModuleTilesComponent,
    FooterComponent,
    ModuleDetailComponent,
    LeftSidebarPageComponent,
    FullPageComponent,
    PastPaperListComponent,
    SearchComponent,
    HomeComponent,
    AllModulesComponent,
    ErrorComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FontAwesomeModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
